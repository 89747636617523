<script setup>
import { watch, triggerRef, shallowRef } from 'vue'

defineOptions({
  inheritAttrs: false
})
const props = defineProps({
  fixed: Boolean,
  items: Array,
  fields: Array,
  tableClass: String,
  theadClass: String,
  tfootClass: String,
  theadTrClass: String,
  tfootTrClass: String,
  tbodyTrClass: [String, Function],
  sortBy: String,
  sortDesc: Boolean,
  stickyHeader: [Boolean, String],
  outlined: [Boolean],
  responsive: [Boolean, String],
  small: [Boolean],
  headRowVariant: [String],
  tableVariant: [String]
})
const emit = defineEmits(['row-hovered', 'row-clicked', 'update:sortBy', 'update:sortDesc', 'sort-changed'])
const itemsRef = shallowRef()
const fieldsRef = shallowRef()

// WATCH

watch(
  () => props.items,
  (newValue) => {
    itemsRef.value = newValue
  },
  { immediate: true }
)
watch(
  () => props.fields,
  (newValue) => {
    if (newValue) {
      fieldsRef.value = newValue.map(field => typeof field === 'string' ? { key: field } : field)
    } else if (props.items?.length > 0) {
      fieldsRef.value = props.items.reduce((accu, curr) => {
        for (const key of Object.keys(curr)) {
          if (!accu.includes(key)) {
            accu.push(key)
          }
        }
        return accu
      }, []).map(key => ({ key }))
    } else {
      fieldsRef.value = []
    }
  },
  { immediate: true }
)

// METHODS

// supports dot notation given the field and item
const fieldValue = (key, item) => {
  return key.split('-->').reduce((acc, part) => acc && acc[part], item)
}

const formatField = (field, item) => field.formatter ? field.formatter(fieldValue(field.key, item), field.key, item) : fieldValue(field.key, item)

const calcTdClasses = (field, item) => {
  // I wonder if there is another way to calc td classes. Running a function for rows x columns seems a bit excessive
  const classes = [field.key, field.key !== field.class ? field.class : '', 'table-cell', field.sticky ? 'new-table-sticky-column' : '']
  if (typeof field.tdClass === 'string') {
    if (!classes.includes(field.tdClass)) {
      classes.push(field.tdClass)
    }
    return classes.join(' ')
  }
  if (typeof field.tdClass === 'function') {
    const value = formatField(field, item)

    return field.tdClass(value, field.key || field.slot, item, classes.join(' '))
  }
  return classes.join(' ')
}

const changeSort = (field) => {
  if (!field.sortable && !field.filter) return
  let sortDesc = props.sortDesc
  const sortBy = field.key
  if (sortBy === props.sortBy) {
    sortDesc = !sortDesc
    emit('update:sortDesc', sortDesc)
  } else {
    emit('update:sortBy', sortBy)
  }
  emit('sort-changed', { sortDesc, sortBy })
}

const toggleDetails = (item) => {
  item._showDetails = !item._showDetails
  triggerRef(itemsRef)
}
const handleRowClick = (item, index, clickEvent) => {
  emit('row-clicked', item)
}
</script>

<template>
  <div :class="[{ 'new-table-sticky-header': stickyHeader, 'table-responsive': responsive }]"
       :style="typeof stickyHeader === 'string' ? `max-height: ${stickyHeader};` : ''">
    <table v-bind="$attrs" :class="[props.tableClass, 'table', 'new-table', { 'fixed': props.fixed, 'border': outlined, 'table-sm': small, [`table-${tableVariant}`]: tableVariant }]">
      <thead :class="props.theadClass">
      <slot name="thead-top"></slot>
      <tr :class="[props.theadTrClass, { [`table-${headRowVariant}`]: headRowVariant } ]">
        <th v-bind="field.thAttr"
            v-for="field in fieldsRef"
            :key="'table-header-' + field.key"
            role="columnheader"
            scope="col"
            :class="['table-heading', field.key, field.class, field.thClass, { 'cursor-pointer': field.sortable || field.filter, 'pr-0': field.sortable || !!(field.filter), 'new-table-sticky-column': field.sticky }]"
            :style="field.thStyle"
            @click="changeSort(field)">
          <div class="d-flex align-items-end heading-row-content">
            <div>
              <slot v-if="$slots[`head(${field.key})`]" :name="`head(${field.key})`" :field="field"
                    :label="field.hasOwnProperty('label') ? field.label : $filters.convertFromCamelCase(field.key)"
                    :column="field.key">
              </slot>
              <slot v-else name="head()" :field="field"
                    :label="field.hasOwnProperty('label') ? field.label : $filters.convertFromCamelCase(field.key)"
                    :column="field.key">
                {{ field.hasOwnProperty('label') ? field.label : $filters.convertFromCamelCase(field.key) }}
              </slot>
            </div>
            <slot name="sort-control" :field="field"
                  :label="field.hasOwnProperty('label') ? field.label : $filters.convertFromCamelCase(field.key)"
                  :column="field.key">
            <span v-if="field.sortable" class="d-flex flex-column justify-content-end sort-icons ml-1">
              <img src="../../assets/icons/bionic/chevron-up.svg"
                   :class="{'active-sort': props.sortBy === field.key && !props.sortDesc}" alt="sort ascending icon"
                   style="position: relative; top: 8px;">
              <img src="../../assets/icons/bionic/chevron-down.svg"
                   :class="{'active-sort': props.sortBy === field.key && props.sortDesc}" alt="sort descending icon">
            </span>
            </slot>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="itemsRef.length === 0">
        <td :colspan="fieldsRef.length" role="cell" class="p-0">
          <slot name="empty"></slot>
        </td>
      </tr>
      <template v-for="(item, index) in itemsRef" :key="'row-' + index">
        <tr
          :class="[typeof props.tbodyTrClass === 'function' ? props.tbodyTrClass(item) : props.tbodyTrClass, {'details-open': item._showDetails}, item._rowVariant]"
          @mouseenter="emit('row-hovered', item)" @click="handleRowClick(item, index, $event)">
          <td v-bind="field.tdAttr"
              v-for="field in fieldsRef"
              :key="'row-' + index + '-field-' + field.key"
              :class="calcTdClasses(field, item)">
            <slot v-if="$slots[`cell(${field.key})`]"
                  :name="`cell(${field.key})`"
                  :field="field"
                  :item="item"
                  :value="formatField(field, item)"
                  :index="index"
                  :detailsShowing="!!item._showDetails"
                  :toggleDetails="() => toggleDetails(item)">
            </slot>
            <slot v-else name="cell()"
                  :field="field"
                  :item="item"
                  :value="formatField(field, item)"
                  :index="index"
                  :detailsShowing="!!item._showDetails"
                  :toggleDetails="() => toggleDetails(item)">
              {{ formatField(field, item) }}
            </slot>
          </td>
        </tr>
        <tr v-if="item._showDetails" :key="item._showDetails" class="details-row">
          <td :colspan="fieldsRef.length" role="cell" class="p-0 details-cell">
            <slot name="row-details" :item="item" :index="index"></slot>
          </td>
        </tr>
      </template>
      </tbody>
      <tfoot v-if="$slots['custom-foot'] || Object.keys($slots).some(slot => slot.startsWith('foot')) || footClone" :class="props.tfootClass">
      <slot name="custom-foot" :fields="fieldsRef">
        <tr :class="props.tfootTrClass">
          <th v-bind="field.thAttr" v-for="field in fieldsRef" :key="'table-footer-' + field.key"
              :class="[field.key, field.class, field.thClass]">
            <slot v-if="$slots[`foot(${field.key})`]" :name="`foot(${field.key})`" :field="field"
                  :label="field.hasOwnProperty('label') ? field.label : $filters.convertFromCamelCase(field.key)"
                  :column="field.key"></slot>

            <slot v-else name="foot()" :field="field"
                  :label="field.hasOwnProperty('label') ? field.label : $filters.convertFromCamelCase(field.key)"
                  :column="field.key">
            </slot>
          </th>
        </tr>
      </slot>
      </tfoot>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.new-table {
  min-width: 100%;

  &.fixed {
    width: 100%;
    table-layout: fixed;
  }
}

tbody {
  tr {
    color: $bionic-1000;

    .table-cell {
      padding: 6px 12px;
      border-top: 1px solid $bionic-300;
    }

    &:nth-child(odd) > .table-cell {
      background-color: $bionic-white;
    }

    &:nth-child(even) > .table-cell {
      background-color: $bionic-white;
    }

    &:hover > .table-cell {
      background-color: $bionic-200;
    }

    &.details-open {
      > .table-cell {
        background-color: $bionic-white;
        box-shadow: inset 0 1px $fluency-blue;
      }

      .table-cell:first-child {
        box-shadow: inset 0 1px $fluency-blue, inset 1px 0 $fluency-blue;
      }

      .table-cell:last-child {
        box-shadow: inset 0 1px $fluency-blue, inset -1px 0 $fluency-blue;
      }
    }

    &.details-row + .details-open {
      > .table-cell {
        box-shadow: none;
      }

      .table-cell:first-child {
        box-shadow: inset 1px 0 $fluency-blue;
      }

      .table-cell:last-child {
        box-shadow: inset -1px 0 $fluency-blue;
      }
    }

    &.details-row {
      .details-cell {
        box-shadow: inset 1px 0 $fluency-blue, inset -1px 0 $fluency-blue, inset 0 -1px $fluency-blue;

        .table-cell:first-child {
          box-shadow: inset 1px 0 $fluency-blue;
        }

        .table-cell:last-child {
          box-shadow: inset -1px 0 $fluency-blue;
        }

        tr:last-child {
          .table-cell {
            box-shadow: inset 0 -1px $fluency-blue;

            &:first-child {
              box-shadow: inset 0 -1px $fluency-blue, inset 1px 0 $fluency-blue;
            }

            &:last-child {
              box-shadow: inset 0 -1px $fluency-blue, inset -1px 0 $fluency-blue;
            }
          }
        }
      }
    }

    .table-cell.last-sticky::after {
      border-color: $border-color;
    }

    &.selected-row > .table-cell {
      background-color: $fluency-lighterblue;
    }

    &.info > .table-cell {
      background-color: $fluency-lighterblue;
    }
  }
}

 thead {
  .text-right .heading-row-content {
    justify-content: end;
  }

  .text-left .heading-row-content {
    justify-content: start;
  }

  th {
    border-top: none;
    border-bottom: none;
    vertical-align: bottom;
    padding: 6px 12px;

    .sort-icons img {
      height: 14px;
      width: 10px;
      opacity: .6;

      &.active-sort {
        opacity: 1;
      }
    }
  }
}

tfoot {
  th {
    //box-shadow: inset 0 1px $border-color;
    vertical-align: top;
  }
}

/*
 * These are the basic classes needed for sticky header and column support, if we decide to add it.
 */
.new-table-sticky-header,
.new-table-responsive,
[class*="table-responsive-"] {
  margin-bottom: 1rem;
  > .new-table {
    margin-bottom: 0;

    > thead > tr > th {
      background-color: rgba(255, 255, 255, 1);
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }

  max-height: 100vh;
  overflow-y: auto;

  @media print {
    overflow-y: visible !important;
    max-height: none !important;
  }
}

.new-table-sticky-header,
.new-table-responsive,
[class*="table-responsive-"] {
  > .new-table {
    > thead,
    > tbody,
    > tfoot {
      > tr > .new-table-sticky-column {
        background-color: rgba(255, 255, 255, 1);
        position: sticky;
        left: 0
      }
    }

    > thead {
      > tr > .new-table-sticky-column {
        z-index: 5;
      }
    }

    > tbody,
    > tfoot {
      > tr > .new-table-sticky-column {
        z-index: 2;
      }
    }
  }
}
</style>
